import React from "react";
import ReactDOM from "react-dom";

import "@jdcfe/yep-react/dist/@jdcfe/yep-react.css";
import "./index.scss";
import App from "./App";
import { Provider } from "react-redux";
import history from "./store/history";
import store from "./store";

import * as serviceWorker from "./serviceWorker";

import { AppContainer } from "react-hot-loader";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return null;
    }
    return this.props.children;
  }
}

ReactDOM.render(
  <AppContainer>
    <Provider store={store}>
      <App history={history} />
    </Provider>
  </AppContainer>,
  document.getElementById("root")
);

serviceWorker.unregister();

window.onpageshow = function(event) {
  if (event.persisted) {
    window.location.reload();
  }
};

if (!window.iosInitUrl) {
  window.iosInitUrl = window.location.href.split("#")[0];
}
