import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

import count from "./counter";
import unReadMsg from "./unReadMsg";
import review from "./clinic/review";
import patients from "./clinic/patients";
import common from "./common/index";
import entities from "./entities";
import pagination from "./pagination";
import inquiry from "./inquiry";

import { connectRouter } from "connected-react-router";
export default (history) =>
  combineReducers({
    router: connectRouter(history),
    count,
    review,
    patients,
    entities,
    pagination,
    common,
    form: formReducer,
    unReadMsg,
    inquiry
  });
