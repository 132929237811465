import {
  COMMON_PATIENT,
  COMMON_SELECT_PATIENT,
  PATIENT_HEALTH_RECORD_SET_MEDICAL_HISTORY_TEMP,
  PATIENT_HEALTH_RECORD_UPDATE_MEDICAL_HISTORY_TEMP,
  PATIENT_HEALTH_RECORD_RESET_MEDICAL_HISTORY_TEMP,
} from '../../constants/ActionTypes';
import {
  PATIENT_HEALTH_RECORD_GET_PATIENT_DETAIL_INFO,
  PATIENT_HEALTH_RECORD_SET_PATIENT_DETAIL_INFO,
  PATIENT_HEALTH_RECORD_RESET_PATIENT_DETAIL_INFO,
} from '@/store/constants/ActionTypes';
import {combineReducers} from 'redux';

const commonReducer = (
  state = {
    patientIndex: -1,
  },
  action
) => {
  switch (action.type) {
    case COMMON_SELECT_PATIENT.SUCCESS:
      return {
        ...state,
        patientIndex: action.data,
      };
    default:
      return state;
  }
};

const PatientHealthRecordGetPatientInfoListReducer = (state = {}, action) => {
  switch (action.type) {
    case COMMON_PATIENT.SUCCESS:
      return action.data;
    case COMMON_PATIENT.FAILURE:
      return state;
    default:
      return state;
  }
};

const patientHealthRecordGetDetailInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case PATIENT_HEALTH_RECORD_GET_PATIENT_DETAIL_INFO.SUCCESS:
      return action.data;
    case PATIENT_HEALTH_RECORD_GET_PATIENT_DETAIL_INFO.FAILURE:
      return state;
    case PATIENT_HEALTH_RECORD_SET_PATIENT_DETAIL_INFO.SUCCESS:
      return {...state, ...action.data};
    case PATIENT_HEALTH_RECORD_SET_PATIENT_DETAIL_INFO.FAILURE:
      return state;
    case PATIENT_HEALTH_RECORD_RESET_PATIENT_DETAIL_INFO.SUCCESS:
      return {};
    case PATIENT_HEALTH_RECORD_RESET_PATIENT_DETAIL_INFO.FAILURE:
      return {};
    default:
      return state;
  }
};

const medicalHistoryInitialState = {
  ownIllHistory: [], //过往病史
  ownIllHistoryContent: [], //过往病史
  ownIllHistoryOther: [],
  surgeryHistory: [], //手术史
  surgeryHistoryContent: [], //手术史
  surgeryHistoryOther: [],
  bloodTransfusionTherapy: 1, //输血治疗 1-无 2-有
  allergySku: [], //过敏药品
  allergySkuContent: [], //过敏药品,
  allergySkuOther: [], //过敏药品,
  allergyFood: [],
  allergyFoodContent: [],
  allergyFoodOther: [],
  allergyContact: [],
  allergyContactContent: [],
  allergyContactOther: [],
  familyIllHistory: [],
  familyIllHistoryContent: [],
  familyIllHistoryOther: [],
  smokeHabit: [],
  smokeHabitContent: [],
  smokeHabitOther: [],
  drinkingHabit: [],
  drinkingHabitContent: [],
  drinkingHabitOther: [],
  habit: [],
  habitContent: [],
  habitOther: [],
};

const medicalHistoryTemp = (state = medicalHistoryInitialState, action) => {
  switch (action.type) {
    case PATIENT_HEALTH_RECORD_SET_MEDICAL_HISTORY_TEMP:
      return {...state, ...action.data};
    case PATIENT_HEALTH_RECORD_UPDATE_MEDICAL_HISTORY_TEMP:
      return {...state, ...action.data};
    case PATIENT_HEALTH_RECORD_RESET_MEDICAL_HISTORY_TEMP.SUCCESS:
      console.log(medicalHistoryInitialState);
      return medicalHistoryInitialState;
    default:
      return state;
  }
};

export default combineReducers({
  common: commonReducer,
  patientHealthRecordGetDetailInfo: patientHealthRecordGetDetailInfoReducer,
  patientHealthRecordGetPatientInfoList: PatientHealthRecordGetPatientInfoListReducer,
  medicalHistoryTemp,
});
