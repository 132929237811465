import { union } from 'ramda';
import { combineReducers } from 'redux';
import { DOCTORS_ACTIONS } from '../constants/ActionTypes';

type Pagination = {
  isFetching: boolean,
  hasMore: boolean,
  ids: array,
  pageNo: number,
  pageSize: number,
  totalCount: number,
  totalPage: number
};

export const initPagination: Pagination = {
  isFetching: false,
  hasMore: true,
  ids: [],
  pageNo: 1 //当前页
};

// Creates a reducer managing pagination, given the action types to handle,
// and a function telling how to extract the key from an action.
const paginate = ({ types, mapActionToKey }) => {
  if (typeof mapActionToKey !== 'function') {
    throw new Error('Expected mapActionToKey to be a function.');
  }

  const { REQUEST, SUCCESS, FAILURE } = types;

  const updatePagination = (state = initPagination, action) => {
    switch (action.type) {
      case REQUEST:
        return {
          ...state,
          isFetching: true
        };
      case SUCCESS:
        const page = action.response.page;
        return {
          ...state,
          isFetching: false,
          ids: union(state.ids, action.response.result),
          pageNo: page.pageNo + 1, //下一页
          totalCount: page.totalCount,
          totalPage: page.totalPage,
          hasMore: page.pageNo < page.totalPage
        };
      case FAILURE:
        return {
          ...state,
          isFetching: false,
          hasMore: false
        };
      default:
        return state;
    }
  };

  return (state = {}, action) => {
    // Update pagination by key
    switch (action.type) {
      case REQUEST:
      case SUCCESS:
      case FAILURE:
        const key = mapActionToKey(action);
        return {
          ...state,
          [key]: updatePagination(state[key], action)
        };
      default:
        return state;
    }
  };
};

const pagination = combineReducers({
  doctorsByType: paginate({
    mapActionToKey: action => action.typeKey,
    types: DOCTORS_ACTIONS
  })
});

export default pagination;
