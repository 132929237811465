import {REVIEW_SELECT_PATIENT, REVIEW_CHANGE_DISEASE, REVIEW_SELECT_TREATMENT} from '../../constants/ActionTypes';

const reviewReducer = (
  state = {
    patientIndex: -1,
    diseaseDesc: '',
    treatmentIndex: -1,
  },
  action
) => {
  switch (action.type) {
    case REVIEW_SELECT_PATIENT:
      return {
        ...state,
        patientIndex: action.data,
      };
    case REVIEW_CHANGE_DISEASE:
      return {
        ...state,
        diseaseDesc: action.data,
      };
    case REVIEW_SELECT_TREATMENT:
      return {
        ...state,
        treatmentIndex: action.data,
      };
    default:
      return state;
  }
};

export default reviewReducer;
