import React, { Fragment } from "react";
import { Route, Switch } from "react-router";
import { Helmet } from "react-helmet";
import Loadable from "react-loadable";
import Loading from "@/components/loading";

const FrontendAuth = Loadable({
  loader: () => import("./pages/FrontendAuth"),
  loading: Loading
});

const MiddlePage = Loadable({
  loader: () => import("./pages/middlepage/MiddlePage"),
  loading: () => null
});

const WXQQMiddlePage = Loadable({
  loader: () => import("./pages/middlepage/WXQQMiddlePage"),
  loading: () => null
});

const MiddlePageTopay = Loadable({
  loader: () => import("./pages/middlepage/MiddlePageTopay"),
  loading: () => null
});

const routes = (
  <Fragment>
    <Helmet titleTemplate="%s" />
    <Switch>
      <Route path="/wxqqmiddlePage" component={WXQQMiddlePage} />
      <Route path="/middlePage" component={MiddlePage} />
      <Route path="/middlePageTopay" component={MiddlePageTopay} />
      <FrontendAuth />
    </Switch>
  </Fragment>
);
export default routes;
