import CreateActions from './CreateActions';

export const REVIEW_SELECT_PATIENT = 'REVIEW_SELECT_PATIENT';
export const REVIEW_CHANGE_DISEASE = 'REVIEW_CHANGE_DISEASE';
export const REVIEW_SELECT_TREATMENT = 'REVIEW_SELECT_TREATMENT';

export const PATIENTS_ACTIONS = CreateActions('PATIENTS', 'FETCH');

export const ADD_ENTITIES = 'ADD_ENTITIES';

export const DOCTORS_ACTIONS = CreateActions('DOCTORS', 'FETCH');
export const DOCTOR_ACTIONS = CreateActions('DOCTOR', 'FETCH');

export const COMMON_PATIENT = CreateActions('COMMON_PATIENT', 'FETCH');
export const COMMON_SELECT_PATIENT = CreateActions('COMMON_SELECT_PATIENT', 'MODIFY');
export const PATIENT_HEALTH_RECORD_GET_PATIENT_DETAIL_INFO = CreateActions(
  'PATIENT_HEALTH_RECORD_GET_PATIENT_DETAIL_INFO',
  'FETCH'
);
export const PATIENT_HEALTH_RECORD_SET_PATIENT_DETAIL_INFO = CreateActions(
  'PATIENT_HEALTH_RECORD_SET_PATIENT_DETAIL_INFO',
  'MODIFY'
);

export const PATIENT_HEALTH_RECORD_RESET_PATIENT_DETAIL_INFO = CreateActions(
  'PATIENT_HEALTH_RECORD_RESET_PATIENT_DETAIL_INFO',
  'MODIFY'
);

export const PATIENT_HEALTH_RECORD_SET_MEDICAL_HISTORY_TEMP = 'PATIENT_HEALTH_RECORD_SET_MEDICAL_HISTORY_TEMP';
export const PATIENT_HEALTH_RECORD_UPDATE_MEDICAL_HISTORY_TEMP = 'PATIENT_HEALTH_RECORD_UPDATE_MEDICAL_HISTORY_TEMP';

export const PATIENT_HEALTH_RECORD_RESET_MEDICAL_HISTORY_TEMP = CreateActions(
  'PATIENT_HEALTH_RECORD_RESET_MEDICAL_HISTORY_TEMP',
  'MODIFY'
);

export const UN_READ_MSG = CreateActions('UN_READMSG', 'FETCH');
