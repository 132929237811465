/**
 * Created by Freeman on 2017/7/1.
 */
import { combineReducers } from 'redux';

import * as entities from './schema';
import { ADD_ENTITIES } from '../../constants/ActionTypes';
import doctorsReducer from './doctors/reducer';
const entityReducers = {
  doctors: doctorsReducer
};

/**
 * Generates a reducer which will handle all entity requests.
 * @return {state}            New state
 * @param key
 */
const createEntityReducer = key => (state = {}, action) => {
  const entityReducer = entityReducers[key];
  // If there is no reducer we should use a placeholder
  const reducer = entityReducer || ((_state = {} || {}) => _state);

  let newState = state;
  if (action.type === ADD_ENTITIES) {
    const data = action.entities[key];

    if (!data) return reducer(state, action);
    newState = { ...state, ...data };
  }
  return reducer(newState, action);
};

const reducers = Object.keys(entities.default).reduce((total, next) => {
  const entity = entities.default[next];
  return {
    ...total,
    [next]: createEntityReducer(next, entity)
  };
}, {});
export default combineReducers(reducers);
