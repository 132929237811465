import React from 'react';
import styles from './index.module.scss';
import loading from '@/assets/imgs/jd_loading.gif';

const Loading = () => (
  <div className={styles.loadingContainer}>
    <img className={styles.loadingImg} src={loading} />
  </div>
);

export default Loading;
