import { PATIENTS_ACTIONS, SELECT_PATIENT } from '../../constants/ActionTypes';

const patientsReducer = (state = [], action) => {
  switch (action.type) {
    case PATIENTS_ACTIONS.SUCCESS:
      return action.data;
    case PATIENTS_ACTIONS.FAILURE:
      return state;
    default:
      return state;
  }
};

export default patientsReducer;
