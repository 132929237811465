const reducer = (
  state = {
    inquirySearchData: {
      chooseType: -1,
      selectItem1: {showName: '全部状态', queryValue: '', queryId: ''},
      selectItem2: {showName: '全部患者', queryValue: '', queryId: ''},
    },
  },
  action
) => {
  switch (action.type) {
    case 'NEW_INQUIRY':
      return Object.assign({}, state, action.payload);
    case 'INQUIRY_SEARCH_DATA':
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
};

export default reducer;
