import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";

import { routerMiddleware } from "connected-react-router";
import createRootReducer from "./reducers";

import history from "./history";

import api from "@/store/middlewares/api";

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  createRootReducer(history),
  //{},
  composeEnhancer(
    applyMiddleware(
      thunk,
      api,
      routerMiddleware(history) // for dispatching history actions
    )
  )
);

// Hot reloading
if (module.hot) {
  // Reload reducers
  module.hot.accept("./reducers", () => {
    store.replaceReducer(createRootReducer(history));
  });
}

export default store;
