import {UN_READ_MSG} from '../constants/ActionTypes';
const unReadMsgData = (state = {}, action) => {
  switch (action.type) {
    case 'AAAA':
      return action.data;
    case 'BBBB':
      return state;
    default:
      return state;
  }
};

export default unReadMsgData;
