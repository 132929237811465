import React from "react";
import PropTypes from "prop-types";

import "./utils/style/reset.scss";

import { ConnectedRouter } from "connected-react-router";
import routes from "./routes";
const imgCheck = () => {
  try {
    return (
      document
        .createElement("canvas")
        .toDataURL("image/webp")
        .indexOf("data:image/webp") == 0
    );
  } catch (err) {
    return false;
  }
};
// 是否支持webp图片
window.isWebp = imgCheck() ? "!q70.webp" : "!q70";

const App = ({ history }) => {
  return <ConnectedRouter history={history}>{routes}</ConnectedRouter>;
};

App.propTypes = {
  history: PropTypes.object
};

export default App;
