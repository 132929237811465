import { normalize } from 'normalizr';
import { omit } from 'ramda';
import { showError } from '../actions/other';
import { ADD_ENTITIES } from '../constants/ActionTypes';
// Extracts the page from API response.
const getPage = response => {
  const page = omit(['data'], response);
  if (!page) {
    return {};
  }

  return page;
};
// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
const callApi = (api, schema) => {
  return api.then(data => {
    const camelizedJson = data.data;
    const page = getPage(data);
    return Object.assign({}, normalize(camelizedJson, schema), { page });
  });
};

// Action key that carries API call info interpreted by this Redux middleware.
export const CALL_API = 'Call API';

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
export default store => next => action => {
  const callAPI = action[CALL_API];
  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  const { api, schema, types, callback } = callAPI;

  if (!schema) {
    throw new Error('Specify one of the exported Schemas.');
  }

  const actionWith = data => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_API];
    return finalAction;
  };

  const { REQUEST, SUCCESS, FAILURE } = types;
  next(actionWith({ type: REQUEST }));

  return callApi(api, schema).then(
    response => {
      store.dispatch({
        type: ADD_ENTITIES,
        ...response
      });

      next(
        actionWith({
          response,
          type: SUCCESS
        })
      );
      if (callback) {
        store.dispatch(callback);
      }
      return response;
    },
    error => {
      store.dispatch(showError(error));
      next(
        actionWith({
          type: FAILURE,
          error: error.message || 'Something bad happened'
        })
      );
    }
  );
};
